import JWTService from "@/modules/core/service/JWTService";
import router from "@/router";

import axios from "axios";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
  // @TODO remove
  // auth: {
  //   username: "fsd",
  //   password: "fsd"
  // }
});

http.interceptors.request.use(
  config => {
    const token = JWTService.getToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error.response);
  }
);

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      router.push({ name: "redirectSSO" });
    }
    return Promise.reject(error.response);
  }
);

export default http;
