<template>
  <div style="height: 100%">
    <div>
      <page-header></page-header>
      <div class="container-div">
        <router-view />
      </div>
    </div>
    <Snackbar />
  </div>
</template>

<script>
import PageHeader from "@/modules/core/views/templates/DefaultLayaut/PageHeader";
import Snackbar from "@/modules/core/views/components/Snackbar";

export default {
  components: {
    PageHeader,
    Snackbar
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styleguide";
@import "@/assets/stylesheets/components";

@include modal-content-situation();

.title {
  margin-bottom: 20px;
  left: 0;
  transition: left 0.4s !important;
  -webkit-transition: left 0.4s !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-subtitle {
    font-size: 1rem;
    color: #ffffff;
    right: 0;
    top: -60px;
  }
}

.action-button {
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 6px;
}

.header-cards {
  display: flex;
  justify-content: space-between;
}

.container-div {
  padding: 3% 4% 5%;
  min-height: 100vh;
}

.components-container {
  background: none !important;
  box-shadow: none !important;

  .container-card {
    display: flex;
    width: 100%;

    .container-first-component {
      width: 30%;
    }

    .container-second-component {
      width: 70%;
      margin-left: 2%;

      ::v-deep .table-header {
        position: inherit !important;
      }

      ::v-deep .divider-forms {
        margin-top: 0;
      }
    }
  }

  ::v-deep .theme--light.v-toolbar.v-sheet {
    display: none !important;
    box-shadow: none !important;
  }

  ::v-deep .page-default-card {
    background: none !important;
    box-shadow: none !important;
  }

  ::v-deep .title {
    display: none;
  }

  .first-component {
    width: 100% !important;
    min-width: 450px !important;
    margin-right: 0 !important;
  }

  .second-component {
    width: 100% !important;
    margin-left: 0 !important;

    ::v-deep .divider-forms.v-divider.theme--light {
      margin-top: 103px !important;
    }
  }
}

.modal-content-situation {
  ::v-deep .v-list-item::before {
    border-radius: 4px !important;
    font-weight: bold;
  }
}
</style>
