import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import core from "@/modules/core/store/";
import inviteds from "@/modules/inviteds/store";
import invite from "@/modules/invite/store";
import invited from "@/modules/invited/store";
import exceptionRequest from "@/modules/exception-request/store";
import inviteUba from "@/modules/invite-uba/store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    inviteds,
    core,
    invite,
    invited,
    inviteUba,
    exceptionRequest
  },
  plugins: [
    createPersistedState({
      key: "abrapa",
      paths: ["inviteds", "invite", "invited"],
      reducer(state) {
        return state?.core?.hasLoggedUser ? state : {};
      }
    })
  ]
});
