import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import ExceptionRequestSbrhviService from "@/modules/exception-request/service/ExceptionRequestSbrhviService";

export const fetchExceptionRequestsSbrhvi = loading(
  async ({ commit }, payload) => {
    const { data } = await ExceptionRequestSbrhviService.get(payload);

    return data;
  }
);

export const cancelExceptionRequestSbrhvi = loading(async ({ commit }, id) => {
  const { data } = await ExceptionRequestSbrhviService.cancel(id);

  return data;
});

export const opinionExceptionRequestSbrhvi = loading(
  async ({ commit }, payload) => {
    const { data } = await ExceptionRequestSbrhviService.opinion(payload);

    return data;
  }
);

export const createExceptionRequestSbrhvi = loading(
  async ({ commit }, payload) => {
    const { data } = await ExceptionRequestSbrhviService.post(payload);

    return data;
  }
);

export const sendNotionReview = loading(async ({ commit }, payload) => {
  const { data } = await ExceptionRequestSbrhviService.sendNotionReview(
    payload
  );

  return data;
});

export const getProducers = loading(async ({ commit }, params) => {
  const { data } = await ExceptionRequestSbrhviService.getProducers(params);

  return data;
});

export const getProducersGroups = loading(async ({ commit }, params) => {
  const { data } = await ExceptionRequestSbrhviService.getProducersGroups(
    params
  );

  return data;
});
