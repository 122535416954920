export const newInviteInitialState = () => {
  return {
    selectedProcessingUnitsInDraft: [],
    selectedProcessingUnits: [],
    selectedResponsibles: [],
    dialogInviteUsers: {
      value: false,
      isEdit: false,
      initialData: {}
    }
  };
};

export const state = {
  newInvite: newInviteInitialState()
};
