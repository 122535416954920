import HttpService from "@/modules/core/network/http-service";

class ProcessingUnitService extends HttpService {
  constructor() {
    super("algodoeiras");
  }

  getInviteds(params) {
    return this.get("convidadas", { params });
  }
}

export default new ProcessingUnitService();
