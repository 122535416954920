import * as MutationTypes from "./mutation-types";

export const toggleSnackbar = ({ commit }, snackbarInfo) => {
  snackbarInfo.snackbar = true;
  commit(MutationTypes.TOGGLE_SNACKBAR, snackbarInfo);
  setTimeout(() => {
    snackbarInfo.snackbar = false;
    commit(MutationTypes.TOGGLE_SNACKBAR, snackbarInfo);
  }, 8000);
};

export const toggleSidebar = ({ commit }) => {
  commit(MutationTypes.TOGGLE_SIDEBAR);
};

export const setLoading = ({ commit }, value) => {
  commit(MutationTypes.SET_LOADING, value);
};

export const updateTableItems = ({ commit }, itemsTable) => {
  commit(MutationTypes.UPDATE_TABLE_ITEMS, itemsTable);
};

export const setHasLoggedUser = ({ commit }) => {
  commit(MutationTypes.SET_HAS_LOGGED_USER);
};

export const setNoHasLoggedUser = ({ commit }) => {
  commit(MutationTypes.SET_NO_HAS_LOGGED_USER);
};
