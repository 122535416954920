import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import InviteUBAService from "../service/InviteUBAService";
import ProcessingUnitService from "../service/ProcessingUnitService";
import InviteService from "@/modules/invite/service/InviteService";
import ProductiveUnitService from "@/modules/invite/service/ProductiveUnitService";
import ResponsibleService from "@/modules/invited/service/ResponsibleService";

export const fetchInvites = loading(async (_params, payload) => {
  const { data } = await InviteUBAService.get(payload);

  return data;
});

export const fetchInviteDetail = loading(async (_params, id) => {
  const { data } = await InviteUBAService.get(id);

  return data;
});

export const fetchResponsiblesABRUBAWithoutGlobalLoading = async (
  _params,
  payload
) => {
  const { data } = await ResponsibleService.getInvitedABRUBA(payload);

  return data;
};

export const sendInviteUba = loading(async (_params, payload) => {
  const { data } = await InviteService.sendInviteUBA(payload);

  return data;
});

export const sendNotionUBA = loading(async (_params, { id, body }) => {
  const { data } = await InviteService.sendNotionUBA(id, body);

  return data;
});

export const resendInviteUBA = loading(async (_params, id) => {
  const { data } = await InviteService.resendInviteUBA(id);

  return data;
});

export const cancelInviteUBA = loading(async (_params, { id, body }) => {
  const { data } = await InviteService.cancelInviteUBA(body, id);

  return data;
});

export const sendNotionCancelInviteUBA = loading(
  async (_params, { id, body }) => {
    const { data } = await InviteService.sendNotionCancelInviteUBA(body, id);

    return data;
  }
);

export const cancelCancelInviteUBA = loading(async (_params, id) => {
  const { data } = await InviteService.cancelCancelInviteUBA(id);

  return data;
});

export const exportInvitedUbasCSV = loading(async (_params, payload) => {
  const { data } = await InviteUBAService.exportCSV(payload);

  return data;
});

export const exportInvitedUbasExcel = loading(async (_params, payload) => {
  const { data } = await InviteUBAService.exportExcel(payload);

  return data;
});

export const getTermUBA = loading(async (_params, id) => {
  const { data } = await InviteService.getTermUBA(id);

  return data;
});

export const clearInviteUba = ({ commit }) =>
  commit(MutationTypes.CLEAR_NEW_INVITE_STATE);

export const fetchProcessingUnits = loading(async (_params, payload) => {
  const { data } = await ProcessingUnitService.get(payload);

  return data;
});

export const fetchProcessingUnitsInvitedsWithoutGlobalLoading = async (
  _params,
  payload
) => {
  const { data } = await ProcessingUnitService.getInviteds(payload);

  return data;
};

export const removeProcessingUnitToInvite = ({ commit }, processingUnitId) =>
  commit(MutationTypes.NEW_INVITE_REMOVE_PROCESSING_UNIT, processingUnitId);

export const selectProcessingUnitToInvite = loading(
  async ({ commit }, processingUnitId) => {
    const { data } = await ProcessingUnitService.get(
      parseInt(processingUnitId, 10)
    );

    commit(MutationTypes.NEW_INVITE_ADD_PROCESSING_UNIT, data);
  }
);

export const removeProcessingUnitInDraftToInvite = (
  { commit },
  processingUnitId
) =>
  commit(
    MutationTypes.NEW_INVITE_REMOVE_PROCESSING_UNIT_IN_DRAFT,
    processingUnitId
  );

export const selectProcessingUnitToInviteInDraft = loading(
  async ({ commit }, processingUnitId) => {
    const { data } = await ProcessingUnitService.get(
      parseInt(processingUnitId, 10)
    );

    commit(MutationTypes.NEW_INVITE_ADD_PROCESSING_UNIT_IN_DRAFT, data);
  }
);

export const clearProcessingUnitInDraftToInvite = ({ commit }) =>
  commit(MutationTypes.CLEAR_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT);

export const addOrUpdateResponsibleToNewInvite = ({ commit }, responsible) => {
  commit(MutationTypes.NEW_INVITE_ADD_OR_UPDATE_RESPONSIBLE, responsible);
};

export const changeInviteProcessingUnitInDraftToSelecteds = ({ commit }) => {
  commit(MutationTypes.CHANGE_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT_TO_SELECTEDS);
  commit(MutationTypes.CLEAR_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT);
};

export const removeResponsibleFromNewInvite = ({ commit }, responsible) => {
  commit(MutationTypes.NEW_INVITE_REMOVE_REPONSIBLE, responsible);
};

export const openDialogResponsible = ({ commit }) =>
  commit(MutationTypes.NEW_INVITE_OPEN_DIALOG_RESPONSIBLE);

export const closeDialogResponsible = ({ commit }) =>
  commit(MutationTypes.NEW_INVITE_CLOSE_DIALOG_RESPONSIBLE);

export const openDialogResponsibleToEdit = ({ commit }, responsible) => {
  commit(MutationTypes.NEW_INVITE_OPEN_DIALOG_TO_EDIT_RESPONSIBLE, responsible);
};

export const fetchProductiveUnitByIdAndHarvestId = loading(
  async (_params, payload) => {
    const { id, safra } = payload;
    const { data } = await ProductiveUnitService.getByIdAndHarvestId(id, safra);
    return data;
  }
);

export const deleteInvitedUba = loading(async (_params, payload) => {
  const { data } = await InviteUBAService.deleteInvitedUba(payload);
  return data;
});

export const validateFormResponsibleUBA = loading(async (_params, payload) => {
  const { data } = await InviteService.validateFormUBA(payload);

  return data;
});

export const fetchProductiveUnitsWithoutGlobalLoading = async (
  _params,
  payload
) => {
  const { data } = await ProductiveUnitService.get(payload);

  return data;
};

export const fetchFiltersInvitedsUBA = loading(async () => {
  const { data } = await InviteUBAService.getFilters();

  return data;
});
