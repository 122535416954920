import * as MutationTypes from "./mutation-types";
import { userInitialState } from "./state";

export const mutations = {
  [MutationTypes.SET_USER](state, user) {
    state.user = user;
  },

  [MutationTypes.RESET_USER](state) {
    state.user = userInitialState();
  }
};
