import { ContentLayout } from "@/modules/core/views/templates";
import { Profile } from "../inviteds/enums/profile.enum";

export default [
  {
    path: "/convidado",
    component: ContentLayout,
    children: [
      {
        name: "terms",
        path: "termos-de-adesao",
        meta: {
          title: "Termos de Adesão aos Programas",
          auth: true,
          roles: [Profile.INVITED.id]
        },
        component: () => import("@/modules/invited/views/pages/Terms.vue")
      }
    ]
  }
];
