function checkEnum(value) {
  return value && this.id === value;
}

export const InvitedSystem = {
  ABR_UBA: {
    id: "ABR_UBA",
    check: checkEnum
  },
  ABR_SBRHVI: {
    id: "ABR_SBRHVI",
    check: checkEnum
  }
};
