export const inviteDetailInitialState = () => ({
  produtor_id: null,
  tipo_produtor: null,
  unidade_produtiva_id: null
});

export const termPageInitialState = () => ({
  stepper: {
    currentStep: 1,
    steps: [
      {
        step: 1,
        label: ""
      },
      {
        step: 2,
        label: "Confirmação da(s) Adesão(ões)"
      }
    ]
  }
});

export const initialState = () => ({
  inviteDetail: inviteDetailInitialState(),
  inviteMode: null,
  termPage: termPageInitialState()
});

export const state = {
  data: initialState()
};
