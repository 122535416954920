import HttpService from "@/modules/core/network/http-service";

class InvitedService extends HttpService {
  constructor() {
    super("convidados");
  }

  getInviteByInvitedIdAndType(id, type) {
    return this.get(id, {}, type);
  }

  getInvitesByInviteIdAndType(id, options) {
    return this.get(
      id,
      {
        params: options
      },
      "convites"
    );
  }
}

export default new InvitedService();
