export const getNewInviteSelectedProcessingUnits = state =>
  state.newInvite.selectedProcessingUnits;

export const getNewInviteSelectedProcessingUnitsIds = state =>
  state.newInvite.selectedProcessingUnits.map(
    selectedProcessingUnit => selectedProcessingUnit.id
  );

export const getNewInviteSelectedProcessingUnitsLength = state =>
  state.newInvite.selectedProcessingUnits.length;

export const getNewInviteSelectedProcessingUnitsInDraft = state =>
  state.newInvite.selectedProcessingUnitsInDraft;

export const getNewInviteSelectedProcessingUnitsInDraftIds = state =>
  state.newInvite.selectedProcessingUnitsInDraft.map(
    selectedProcessingUnit => selectedProcessingUnit.id
  );

export const getNewInviteSelectedProcessingUnitsInDraftLength = state =>
  state.newInvite.selectedProcessingUnitsInDraft.length;

export const getNewInviteSelectedResponsibles = state =>
  state.newInvite.selectedResponsibles;

export const getNewInviteSelectedResponsiblesLength = state =>
  state.newInvite.selectedResponsibles.length;

export const getNewInviteDialogResponsibles = state =>
  state.newInvite.dialogInviteUsers;
