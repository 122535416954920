export const NEW_INVITE_ADD_PROCESSING_UNIT = "NEW_INVITE_ADD_PROCESSING_UNIT";
export const NEW_INVITE_ADD_PROCESSING_UNIT_IN_DRAFT =
  "NEW_INVITE_ADD_PROCESSING_UNIT_IN_DRAFT";
export const NEW_INVITE_REMOVE_PROCESSING_UNIT_IN_DRAFT =
  "NEW_INVITE_REMOVE_PROCESSING_UNIT_IN_DRAFT";
export const NEW_INVITE_REMOVE_PROCESSING_UNIT =
  "NEW_INVITE_REMOVE_PROCESSING_UNIT";
export const NEW_INVITE_ADD_OR_UPDATE_RESPONSIBLE =
  "NEW_INVITE_ADD_RESPONSIBLE";
export const NEW_INVITE_REMOVE_REPONSIBLE = "NEW_INVITE_REMOVE_REPONSIBLE";
export const NEW_INVITE_OPEN_DIALOG_RESPONSIBLE =
  "NEW_INVITE_OPEN_DIALOG_RESPONSIBLE";
export const NEW_INVITE_OPEN_DIALOG_TO_EDIT_RESPONSIBLE =
  "NEW_INVITE_OPEN_DIALOG_TO_EDIT_RESPONSIBLE";
export const NEW_INVITE_CLOSE_DIALOG_RESPONSIBLE =
  "NEW_INVITE_CLOSE_DIALOG_RESPONSIBLE";
export const CLEAR_NEW_INVITE_STATE = "CLEAR_NEW_INVITE_STATE";
export const CLEAR_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT =
  "CLEAR_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT";
export const CHANGE_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT_TO_SELECTEDS =
  "CHANGE_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT_TO_SELECTEDS";
