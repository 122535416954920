import { isNumber } from "lodash";

function checkEnum(value) {
  return isNumber(value) && this.id === value;
}

export const Profile = {
  STATE: {
    id: 1,
    description: "Estadual",
    check: checkEnum
  },
  ABRAPA: {
    id: 2,
    description: "Abrapa",
    check: checkEnum
  },
  INVITED: {
    id: 3,
    description: "Convidado",
    check: checkEnum
  }
};
