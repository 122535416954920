<template>
  <div style="height: 100%">
    <page-header></page-header>
    <div class="container-div">
      <router-view />
    </div>
    <page-footer />
    <Snackbar />
  </div>
</template>

<script>
import PageHeader from "@/modules/core/views/templates/DefaultLayaut/PageHeader";
import PageFooter from "@/modules/core/views/templates/DefaultLayaut/PageFooter";
import Snackbar from "@/modules/core/views/components/Snackbar";

export default {
  components: {
    PageHeader,
    PageFooter,
    Snackbar
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styleguide";

.container-div {
  padding: 1% 2% 2%;
  height: 100%;
}
</style>
