import jwt_decode from "jwt-decode";

const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY;

class JwtService {
  getToken() {
    const data = localStorage.getItem(TOKEN_KEY);
    if (data) {
      const obj = JSON.parse(data);
      return obj.access;
    }
  }

  isAuthenticated() {
    return !!localStorage.getItem(TOKEN_KEY);
  }

  async storeToken(token) {
    if (token) {
      localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
      return await jwt_decode(token.access);
    }
  }

  logout() {
    localStorage.clear();
  }
}

export default new JwtService();
