<template>
  <v-card flat color="#f4f4f4">
    <v-toolbar height="75" color="#ffffff" flat>
      <div class="d-flex flex-wrap justify-space-between fill">
        <div class="d-flex align-center">
          <router-link :to="'/'" class="no-decoration mr-15">
            <img src="@/assets/logo-convites-abrapa.jpg" height="60" />
          </router-link>

          <template v-for="item in menuItems">
            <router-link
              :to="item.path"
              class="no-decoration mr-8 primary--text menu-item"
              :key="item.path"
              v-if="canSeeMenu(item.id)"
            >
              <span :class="{ 'menu-item-selected': isActive(item.path) }">
                {{ item.description }}
              </span>
            </router-link>
          </template>
        </div>

        <div class="d-flex align-center" v-if="user">
          <span v-if="hasLoggedUser && canSeeUserInfo"
            >Bem vindo <strong>{{ user.nome_completo | first_name }}</strong> -
            {{ cpf | clear_cpf_cnpj }}</span
          >
          <v-btn
            v-if="hasLoggedUser"
            class="d-flex align-center justify-center primary--text"
            text
            @click="doLogout"
          >
            <v-icon>mdi-logout</v-icon>
            Sair
          </v-btn>
        </div>
      </div>
    </v-toolbar>

    <slot />
  </v-card>
</template>

<script>
import { format } from "@fnando/cpf";
import { mapActions, mapGetters } from "vuex";
import { Profile } from "@/modules/inviteds/enums/profile.enum";
import { InvitedSystem } from "@/modules/core/enums/invited-system.enum";

const menuEnum = {
  ABR_SBRHVI: "abr_sbrhvi",
  EXCEPTION_SBRHVI: "exception_sbrhvi",
  UBA: "uba"
};

export default {
  name: "PageHeader",

  data: () => ({
    menuItems: [
      {
        id: menuEnum.ABR_SBRHVI,
        path: "/",
        description: "Convites ABR e SBRHVI"
      }
      ,{
       id: menuEnum.UBA,
       path: "/convites-uba",
       description: "Convites ABR-UBA"
      }
    ]
  }),

  computed: {
    ...mapGetters({
      user: "inviteds/getUser",
      hasLoggedUser: "getHasLoggedUser"
    }),

    cpf() {
      if (this.user) {
        return format(this.user.cpf);
      }
      return "";
    },

    canSeeUserInfo() {
      return (
        this.$vuetify.breakpoint?.name !== "xs" &&
        this.$vuetify.breakpoint?.name !== "sm"
      );
    }
  },

  methods: {
    ...mapActions("inviteds", ["logout"]),
    ...mapActions(["setNoHasLoggedUser"]),

    async doLogout() {
      this.setNoHasLoggedUser();
      this.logout();
      window.location.replace(process.env.VUE_APP_SSO_URL);
    },

    isActive(path) {
      return this.$route.path === path;
    },

    canSeeMenu(menuId) {
      if (!this.hasLoggedUser) {
        return false;
      }

      if (menuId === menuEnum.ABR_SBRHVI) {
        return this.canSeeInviteABRSBRHVI();
      }

      if (menuId === menuEnum.UBA) {
        return this.canSeeInviteABRUBA();
      }

      return [Profile.STATE.id, Profile.ABRAPA.id].includes(
        this.user.profile.id
      );
    },

    userIsAbrapa() {
      return Profile.ABRAPA.check(this.user?.profile?.id);
    },

    userIsState() {
      return Profile.STATE.check(this.user?.profile?.id);
    },

    canSeeInviteABRSBRHVI() {
      if (Profile.INVITED.check(this.user?.profile?.id)) {
        if (!this.user?.sistemas_convidados?.length) {
          return false;
        }
        return this.user.sistemas_convidados.some(invitedSystem =>
          InvitedSystem.ABR_SBRHVI.check(invitedSystem)
        );
      }
      return this.userIsAbrapa() || this.userIsState();
    },

    canSeeInviteABRUBA() {
      if (Profile.INVITED.check(this.user?.profile?.id)) {
        if (!this.user?.sistemas_convidados?.length) {
          return false;
        }
        return this.user.sistemas_convidados.some(invitedSystem =>
          InvitedSystem.ABR_UBA.check(invitedSystem)
        );
      }
      return this.userIsAbrapa() || this.userIsState();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styleguide";

.no-decoration {
  text-decoration: none;
}
.menu-item {
  font-size: 18px;
  font-weight: 550;

  &:not(:last-child) {
    &:after {
      content: "";
      margin-left: 32px;
      border-right: 1px solid $secondary-color-1;
    }
  }
}
.menu-item-selected {
  border-bottom: 1px solid $primary-color-1;
}
</style>
