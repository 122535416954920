import * as MutationTypes from "./mutation-types";
import { newInviteInitialState } from "./state";

export const mutations = {
  [MutationTypes.CLEAR_NEW_INVITE_STATE](state) {
    state.newInvite = newInviteInitialState();
  },

  [MutationTypes.NEW_INVITE_ADD_PROCESSING_UNIT](state, processingUnit) {
    state.newInvite = {
      ...state.newInvite,
      selectedProcessingUnits: [
        ...state.newInvite.selectedProcessingUnits,
        processingUnit
      ]
    };
  },

  [MutationTypes.NEW_INVITE_REMOVE_PROCESSING_UNIT](state, processingUnitId) {
    state.newInvite = {
      ...state.newInvite,
      selectedProcessingUnits: [
        ...state.newInvite.selectedProcessingUnits.filter(
          selectedProcessingUnit =>
            selectedProcessingUnit.id !== processingUnitId
        )
      ]
    };
  },

  [MutationTypes.NEW_INVITE_ADD_PROCESSING_UNIT_IN_DRAFT](
    state,
    processingUnit
  ) {
    state.newInvite = {
      ...state.newInvite,
      selectedProcessingUnitsInDraft: [
        ...state.newInvite.selectedProcessingUnitsInDraft,
        processingUnit
      ]
    };
  },

  [MutationTypes.NEW_INVITE_REMOVE_PROCESSING_UNIT_IN_DRAFT](
    state,
    processingUnitId
  ) {
    state.newInvite = {
      ...state.newInvite,
      selectedProcessingUnitsInDraft: [
        ...state.newInvite.selectedProcessingUnitsInDraft.filter(
          selectedProcessingUnit =>
            selectedProcessingUnit.id !== processingUnitId
        )
      ]
    };
  },

  [MutationTypes.CLEAR_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT](state) {
    state.newInvite = {
      ...state.newInvite,
      selectedProcessingUnitsInDraft: []
    };
  },

  [MutationTypes.CHANGE_NEW_INVITE_PROCESSING_UNIT_IN_DRAFT_TO_SELECTEDS](
    state
  ) {
    state.newInvite = {
      ...state.newInvite,
      selectedProcessingUnits: [
        ...state.newInvite.selectedProcessingUnitsInDraft
      ]
    };
  },

  [MutationTypes.NEW_INVITE_REMOVE_REPONSIBLE](state, responsibleToRemove) {
    state.newInvite = {
      ...state.newInvite,
      selectedResponsibles: state.newInvite.selectedResponsibles.filter(
        responsible => responsible.cpf !== responsibleToRemove.cpf
      )
    };
  },

  [MutationTypes.NEW_INVITE_ADD_OR_UPDATE_RESPONSIBLE](state, newResponsible) {
    state.newInvite = {
      ...state.newInvite,
      selectedResponsibles: [
        ...state.newInvite.selectedResponsibles.filter(
          responsible => responsible.cpf !== newResponsible.cpf
        ),
        newResponsible
      ]
    };
  },

  [MutationTypes.NEW_INVITE_REMOVE_REPONSIBLE](state, responsibleToRemove) {
    state.newInvite = {
      ...state.newInvite,
      selectedResponsibles: state.newInvite.selectedResponsibles.filter(
        responsible => responsible.cpf !== responsibleToRemove.cpf
      )
    };
  },

  [MutationTypes.NEW_INVITE_OPEN_DIALOG_RESPONSIBLE](state) {
    state.newInvite = {
      ...state.newInvite,
      dialogInviteUsers: {
        value: true,
        isEdit: false,
        initialData: {}
      }
    };
  },

  [MutationTypes.NEW_INVITE_CLOSE_DIALOG_RESPONSIBLE](state) {
    state.newInvite = {
      ...state.newInvite,
      dialogInviteUsers: {
        value: false,
        isEdit: false,
        initialData: {}
      }
    };
  },

  [MutationTypes.NEW_INVITE_OPEN_DIALOG_TO_EDIT_RESPONSIBLE](
    state,
    responsible
  ) {
    state.newInvite = {
      ...state.newInvite,
      dialogInviteUsers: {
        value: true,
        isEdit: true,
        initialData: { ...responsible }
      }
    };
  }
};
