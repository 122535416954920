export const SET_INVITE_DETAIL = "SET_INVITE_DETAIL";
export const RESET_INVITE_STATE = "RESET_INVITE_STATE";
export const RESET_INVITE_DETAIL = "RESET_INVITE_DETAIL";

export const TERM_PAGE_RESET_STEP = "TERM_PAGE_RESET_STEP";
export const TERM_PAGE_SET_FIRST_STEP_LABEL = "TERM_PAGE_SET_FIRST_STEP_LABEL";
export const TERM_PAGE_SET_SECOND_STEP_LABEL =
  "TERM_PAGE_SET_SECOND_STEP_LABEL";
export const TERM_PAGE_SET_CURRENT_STEP = "TERM_PAGE_SET_CURRENT_STEP";

export const SET_INVITE_MODE = "SET_INVITE_MODE";
