import HttpService from "@/modules/core/network/http-service";

class InviteService extends HttpService {
  constructor() {
    super("convites");
  }

  getById(id) {
    return this.get(id);
  }

  saveInvite(payload) {
    return this.post(payload, "enviar_convites");
  }

  resendInvite(payload) {
    const { produtor_id, tipo_produtor, unidade_produtiva_id } = payload;
    const URI = `${this.resource}/${produtor_id}/${tipo_produtor}/${unidade_produtiva_id}/reenviar_convites`;
    return this.http.post(this._normalizeUrl(URI));
  }

  resendInviteSbrhvi(payload) {
    const { produtor_id, tipo_produtor } = payload;

    return this.post({}, `${produtor_id}/${tipo_produtor}/reenviar_convites`);
  }

  validateForm(payload) {
    return this.post(payload, "validar_responsavel");
  }

  validateFormUBA(payload) {
    return this.post(payload, "validar_responsavel_uba");
  }

  sendNotion(payload, id) {
    return this.patch(payload, "parecer", id);
  }
  cancelInviteSBRHVI(payload) {
    return this.patch(payload, "cancelar/sbrhvi");
  }
  cancelInviteProductiveUnity(payload) {
    return this.patch(payload, "cancelar/up");
  }

  cancelInviteResponsibleProductiveUnity(payload) {
    return this.patch(payload, "cancelar/responsavel");
  }

  cancelInviteUBA(payload, id) {
    return this.patch(payload, `${id}/cancelar/uba`);
  }

  sendNotionCancelInviteUBA(payload, id) {
    return this.patch(payload, `${id}/parecer/cancelar/uba`);
  }

  cancelCancelInviteUBA(id) {
    return this.patch({}, `${id}/cancelar/cancelar/uba`);
  }

  getProductiveUnit(payload) {
    const { produtor_id, tipo_produtor, unidade_produtiva_id } = payload;
    const URI = `${produtor_id}/${tipo_produtor}/${unidade_produtiva_id}`;
    return this.get(URI);
  }

  getTermAbrBci(payload) {
    const { produtor_id, tipo_produtor, unidade_produtiva_id } = payload;
    const URI = `${produtor_id}/${tipo_produtor}/${unidade_produtiva_id}/termos/abr`;
    return this.get(URI);
  }

  getTermDownload(payload) {
    const { produtor_id, tipo_produtor, unidade_produtiva_id } = payload;
    const URI = `${produtor_id}/${tipo_produtor}/${unidade_produtiva_id}/termos`;
    return this.get(URI);
  }

  getTermSbrHvi(payload) {
    const { produtor_id, tipo_produtor } = payload;
    const URI = `${produtor_id}/${tipo_produtor}/termos/sbrhvi`;
    return this.get(URI);
  }

  getTerm(payload) {
    const { produtor_id, tipo_produtor, abr_bci, sbrhvi } = payload;
    const URI = `${produtor_id}/${tipo_produtor}/gerar_termo`;
    return this.patch(
      {
        abr_bci,
        sbrhvi
      },
      URI
    );
  }

  getTermUBA(id) {
    return this.patch({}, `${id}/gerar_termo_uba`);
  }

  resendInviteUBA(id) {
    return this.get(`${id}/reenviar_convites`);
  }

  sendNotionAbrBci(payload) {
    const {
      produtor_id,
      tipo_produtor,
      unidade_produtiva_id,
      aceite_bci,
      aceitou,
      aceite_sbrhvi
    } = payload;
    const URI = `${this.resource}/${produtor_id}/${tipo_produtor}/${unidade_produtiva_id}/parecer/abr_bci`;
    return this.http.patch(this._normalizeUrl(URI), {
      aceite_bci,
      aceitou,
      aceite_sbrhvi
    });
  }

  sendNotionSbrHvi(payload) {
    const { produtor_id, tipo_produtor, aceitou } = payload;
    const URI = `${this.resource}/${produtor_id}/${tipo_produtor}/parecer/sbrhvi`;
    return this.http.patch(this._normalizeUrl(URI), {
      aceitou
    });
  }

  sendNotionUBA(id, payload) {
    const { aceitou } = payload;
    const URI = `${this.resource}/${id}/parecer/uba`;
    return this.http.patch(this._normalizeUrl(URI), {
      aceitou
    });
  }

  updateManagerLeader(keys, payload) {
    const { produtor_id, tipo_produtor } = keys;
    const URI = `${produtor_id}/${tipo_produtor}/alterar_responsavel`;

    return this.patch(payload, this._normalizeUrl(URI));
  }

  sendInviteUBA(payload) {
    return this.post(payload, "enviar_convites_uba");
  }
}

export default new InviteService();
