export const SET_SYSTEMS = "SET_SYSTEMS";
export const RESET_NEW_INVITE = "RESET_NEW_INVITE";

// Productive Units
export const ADD_PRODUCTIVE_UNIT = "ADD_PRODUCTIVE_UNIT";
export const REMOVE_PRODUCTIVE_UNIT = "REMOVE_PRODUCTIVE_UNIT";

export const SET_SBRHVI_USER = "SET_SBRHVI_USER";
export const REMOVE_SBRHVI_USER = "REMOVE_SBRHVI_USER";

export const ADD_ABR_USER = "ADD_ABR_USER";
export const EDIT_ABR_USER = "EDIT_ABR_USER";
export const SET_PRODUCER_GROUP = "SET_PRODUCER_GROUP";
export const CLEAR_PRODUCER_GROUP = "CLEAR_PRODUCER_GROUP";
export const REMOVE_ABR_USER = "REMOVE_ABR_USER";
