export function loading(actionFn) {
  return async function(context, payload) {
    this.dispatch("setLoading", true);
    try {
      return await actionFn.call(this, context, payload);
    } finally {
      this.dispatch("setLoading", false);
    }
  };
}
