import { loading } from "@/modules/core/helpers/loading";
import InviteService from "@/modules/invite/service/InviteService";
import ProducersGroupService from "@/modules/invite/service/ProducersGroupService";
import ProducerService from "@/modules/invite/service/ProducersService";
import ProductiveUnitService from "@/modules/invite/service/ProductiveUnitService";
import { ProducerType } from "@/modules/core/enums/producer-type.enum";
import { InviteStatus } from "@/modules/core/enums/invite-status.enum";

import * as MutationTypes from "./mutation-types";

export const setSystems = ({ commit, dispatch }, systems) => {
  dispatch("resetNewInvite");
  commit(MutationTypes.SET_SYSTEMS, systems);
};

export const resetNewInvite = ({ commit }) => {
  commit(MutationTypes.RESET_NEW_INVITE);
};

// Productive Unit

export const getProducer = loading(async ({ commit }, id) => {
  const { data } = await ProducerService.getById(id);
  return data;
});

export const getProducers = loading(async ({ commit }, params) => {
  const { data } = await ProducerService.get(params);
  return data;
});

export const getProducersGroups = loading(async ({ commit }, params) => {
  const { data } = await ProducersGroupService.get(params);
  return data;
});

export const getProducersGroup = loading(async ({ commit }, id) => {
  const { data } = await ProducersGroupService.getById(id);
  return data;
});

export const saveInvite = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.saveInvite(payload);
  return data;
});

export const addProductiveUnit = ({ commit }, productiveUnit) => {
  commit(MutationTypes.ADD_PRODUCTIVE_UNIT, productiveUnit);
};
export const removeProductiveUnit = ({ commit }, productiveUnit) => {
  commit(MutationTypes.REMOVE_PRODUCTIVE_UNIT, productiveUnit);
};

export const setSbrHviUser = ({ commit }, user) => {
  commit(MutationTypes.SET_SBRHVI_USER, user);
};

export const removeSbrHviUser = ({ commit }, user) => {
  commit(MutationTypes.REMOVE_SBRHVI_USER, user);
};

export const addAbrUser = ({ commit }, user) => {
  commit(MutationTypes.ADD_ABR_USER, user);
};

export const editAbrUser = ({ commit }, user) => {
  commit(MutationTypes.EDIT_ABR_USER, user);
};

export const getTermsUrls = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.getTerm(payload);
  return data;
});

export const setHarvestCertification = loading(async ({ commit }, payload) => {
  const { data } = await ProductiveUnitService.setHarvestCertification(payload);
  return data;
});

export const setProducerOrGroup = async (
  { commit, dispatch },
  producerOrGroup
) => {
  const action = {
    value: "getProducer"
  };

  if (producerOrGroup.tipo === ProducerType.GROUP.id) {
    action.value = "getProducersGroup";
  }

  const producerOrGroupResponse = await dispatch(
    action.value,
    producerOrGroup.id
  );

  const hasInvitedSbrhvi = producerOrGroupResponse.responsaveis_sbrhvi?.length;

  const hasInvitedSbrhviRefusedOrCancelled = producerOrGroupResponse
    .responsaveis_sbrhvi?.length
    ? [
        InviteStatus.INVITE_REFUSED.id,
        InviteStatus.INVITE_CANCELED.id
      ].includes(
        producerOrGroupResponse.responsaveis_sbrhvi[0].status_sbrhvi.id
      )
    : false;

  if (hasInvitedSbrhvi && !hasInvitedSbrhviRefusedOrCancelled) {
    commit(
      MutationTypes.SET_SBRHVI_USER,
      producerOrGroupResponse.responsaveis_sbrhvi[0]
    );
  } else {
    commit(MutationTypes.REMOVE_SBRHVI_USER);
  }

  commit(MutationTypes.SET_PRODUCER_GROUP, {
    ...producerOrGroupResponse,
    tipo: producerOrGroup.tipo
  });
};

export const clearProducerOrGroup = async ({ commit, dispatch }) => {
  commit(MutationTypes.CLEAR_PRODUCER_GROUP);
};

export const removeAbrUser = ({ commit }, user) => {
  commit(MutationTypes.REMOVE_ABR_USER, user);
};

export const resendInvite = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.resendInvite(payload);

  return data;
});

export const resendInviteSbrhvi = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.resendInviteSbrhvi(payload);

  return data;
});

export const fetchInvite = loading(async ({ commit }, inviteId) => {
  const { data } = await InviteService.getById(inviteId);

  return data;
});

export const fetchProductiveUnit = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.getProductiveUnit(payload);

  return data;
});

export const fetchAbrTerm = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.getTermAbrBci(payload);

  return data;
});

export const fetchSbrHviTerm = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.getTermSbrHvi(payload);

  return data;
});

export const fetchTermDownload = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.getTermDownload(payload);

  return data;
});

export const sendNotionAbrBci = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.sendNotionAbrBci(payload);

  return data;
});

export const sendNotionSbrHvi = loading(async ({ commit }, payload) => {
  const { data } = await InviteService.sendNotionSbrHvi(payload);

  return data;
});

export const cancelInviteSBRHVI = async ({ commit }, payload) => {
  const { data } = await InviteService.cancelInviteSBRHVI(payload);

  return data;
};

export const cancelInviteProductiveUnity = async ({ commit }, payload) => {
  const { data } = await InviteService.cancelInviteProductiveUnity(payload);

  return data;
};
export const cancelInviteResponsibleProductiveUnity = async (
  { commit },
  payload
) => {
  const { data } = await InviteService.cancelInviteResponsibleProductiveUnity(
    payload
  );

  return data;
};

export const updateManagerLeader = async ({ commit }, { keys, payload }) => {
  const { data } = await InviteService.updateManagerLeader(keys, payload);

  return data;
};
