import HttpService from "../../core/network/http-service";

class UserHttpService extends HttpService {
  constructor() {
    super("usuarios");
  }

  authUser(token) {
    return this.post(token, "login");
  }

  fetchUser() {
    return this.get("logado");
  }
}

export default new UserHttpService();
