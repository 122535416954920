import RoutesCore from "@/modules/core/views/router";
import RoutesInvite from "@/modules/invite/router";
import RoutesInvited from "@/modules/invited/router";
import RoutesInviteds from "@/modules/inviteds/router";
import RoutesExceptionRequests from "@/modules/exception-request/router";
import RoutesInviteUBA from "@/modules/invite-uba/router";

export default [
  ...RoutesCore,
  ...RoutesInvite,
  ...RoutesInvited,
  ...RoutesInviteds,
  ...RoutesExceptionRequests,
  ...RoutesInviteUBA
];
