import * as MutationTypes from "./mutation-types";
import InvitedService from "@/modules/invited/service/InvitedService";
import { loading } from "@/modules/core/helpers/loading";
import ResponsibleService from "../service/ResponsibleService";
import ConvidadosService from "@/modules/invite/service/ConvidadosService";

export const fetchInviteByInvitedIdAndType = loading(
  async ({ commit }, { invitedId, type }) => {
    const { data } = await InvitedService.getInviteByInvitedIdAndType(
      invitedId,
      type
    );

    return data;
  }
);

export const fetchInvitesByInvited = loading(
  async ({ commit }, { id, page, limit }) => {
    const { data } = await InvitedService.getInvitesByInviteId(id, {
      page,
      limit
    });

    return data;
  }
);

export const setInviteDetail = ({ commit }, payload) =>
  commit(MutationTypes.SET_INVITE_DETAIL, payload);

export const resetInviteState = ({ commit }) => {
  commit(MutationTypes.RESET_INVITE_STATE);
};

export const reseTermPageStep = ({ commit }) => {
  commit(MutationTypes.TERM_PAGE_RESET_STEP);
};

export const resetTermPageInviteDetail = ({ commit }) =>
  commit(MutationTypes.RESET_INVITE_DETAIL);

export const setTermPageFirstStepLabel = ({ commit }, payload) => {
  commit(MutationTypes.TERM_PAGE_SET_FIRST_STEP_LABEL, payload);
};

export const setTermPageSecondStepLabel = ({ commit }, payload) => {
  commit(MutationTypes.TERM_PAGE_SET_SECOND_STEP_LABEL, payload);
};

export const setTermPageCurrentStep = ({ commit }, payload) => {
  commit(MutationTypes.TERM_PAGE_SET_CURRENT_STEP, payload);
};

export const setInviteMode = ({ commit }, inviteMode) => {
  commit(MutationTypes.RESET_INVITE_STATE);
  commit(MutationTypes.SET_INVITE_MODE, inviteMode);
};

export const updateResponsible = loading(async ({ commit }, payload) =>
  ResponsibleService.update(payload.id, payload)
);

export const fetchDownloadExcelFile = loading(async ({ commit }, payload) => {
  const { data } = await ConvidadosService.getDownloadExcelFile();
  return data;
});
