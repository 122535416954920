import { ContentLayout } from "@/modules/core/views/templates";
import { AuthGuard } from "../core/helpers/AuthGuard";
import { Profile } from "../inviteds/enums/profile.enum";

export default [
  {
    path: "/convite",
    component: ContentLayout,
    children: [
      {
        name: "newInvite",
        path: "",
        meta: {
          title: "Novo Convite",
          auth: true,
          sidebar: "Convite",
          roles: [Profile.STATE.id]
        },
        component: () => import("@/modules/invite/views/pages/NewInvite.vue"),
        beforeEnter: AuthGuard
      }
    ]
  }
];
