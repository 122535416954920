import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import HttpService from "@/modules/core/network/http-service";
import JWTService from "@/modules/core/service/JWTService";
import UserHttpService from "@/modules/inviteds/service/UserHttpService";
import UserService from "../service/UserService";

export const login = async ({ commit }, token) => {
  try {
    const { data } = await UserHttpService.authUser({ token });

    await JWTService.storeToken(data);

    const { data: user } = await UserHttpService.fetchUser();

    user.profile = UserService.getRoleUser(user);

    commit(MutationTypes.SET_USER, user);

    return user;
  } catch (error) {
    console.error(error);
  }
};

export const logout = async ({ commit }) => {
  JWTService.logout();
  commit(MutationTypes.RESET_USER);
};

const httpService = new HttpService("convidados");
export const fetchInvites = loading(async ({ commit }, filters) => {
  const { data } = await httpService.get(filters);
  return data;
});
