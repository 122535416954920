export const state = {
  selectedSystems: [],
  newInvite: {
    produtor: {
      nome: "",
      habilitar_convite_sbrhvi: false,
      possui_responsavel_sbrhvi: false,
      unidades_produtivas: [],
      responsaveis_sbrhvi: [],
      responsaveis_sbrhvi_registrados: []
    },
    produtor_id: null,
    tipo_produtor: null,
    sbrhvi: {
      convidado: {
        nome: null,
        cpf: null
      }
    },
    abr: {
      convidados: [],
      unidades_produtivas: []
    }
  }
};
