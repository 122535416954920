<template>
  <v-dialog v-model="active" persistent origin="center left" width="500">
    <v-card>
      <v-card-title class="mb-8 text-normal">{{ title }} </v-card-title>

      <v-card-text v-if="infoText">
        <p>{{ infoText }}</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-layout justify-end>
          <v-btn @click="close" class="mr-5" color="#FFF">{{
            $t("buttons.cancel")
          }}</v-btn>
          <v-btn color="primary" @click="confirm()">{{
            $t("buttons.confirm")
          }}</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogConfirmAction",

  data: () => ({
    active: false,
    title: null,
    infoText: null,
    callback: undefined
  }),

  methods: {
    open(options, callback) {
      if (!options) {
        this.title = this.$t("labels.confirm_action");
      }
      if (typeof options === "string") {
        this.title = options;
      }
      if (!!options && typeof options === "object") {
        this.title = options.title ?? this.$t("labels.confirm_action");
        this.infoText = options.infoText;
      }
      this.active = true;
      this.callback = callback;
    },

    clear() {
      this.title = null;
      this.infoText = null;
      this.callback = undefined;
    },

    close() {
      this.active = false;
      this.$emit("confirmAction", false);
      this.clear();
    },

    confirm() {
      if (typeof this.callback === "function") {
        this.callback();
      }
      this.active = false;
      this.clear();
    }
  }
};
</script>

<style lang="scss" scoped></style>
