import _ from "lodash";
import http from "./http";

class HttpService {
  constructor(resource) {
    this.http = http;
    this.resource = resource;
  }

  get(params, config, route) {
    let request = this.resource;
    // Ex. GET /resource?featured=1&active=1
    let options = { params };

    if (typeof params === "string" || typeof params === "number") {
      // Ex. GET /resource/featured
      request = `${this.resource}/${params}`;

      if (_.isEmpty(route) === false) {
        request += `/${route}`;
      }
      options = config;
    }
    return this.http.get(this._normalizeUrl(request), options);
  }

  patch(body, route, id) {
    let resource = this.resource;

    if (id) {
      resource += `/${id}`;
    }

    if (_.isEmpty(route) === false) {
      resource += `/${route}`;
    }

    return this.http.patch(this._normalizeUrl(resource), body);
  }

  post(body, route, config) {
    let resource = this.resource;

    if (_.isEmpty(route) === false) {
      resource = `${this.resource}/${route}`;
    }

    return this.http.post(this._normalizeUrl(resource), body, config);
  }

  put(body, route) {
    let resource = this.resource;

    if (_.isEmpty(route) === false) {
      resource = `${this.resource}/${route}`;
    }

    return this.http.put(this._normalizeUrl(resource), body);
  }

  delete(params) {
    let request = this.resource;
    let options;

    if (typeof params === "string") {
      // Ex. DELETE /resource/featured
      request = `${this.resource}/${params}`;
    } else {
      // Ex. DELETE /resource?featured=1&active=1
      options = { params };
    }

    return this.http.delete(this._normalizeUrl(request), options);
  }

  _normalizeUrl(url) {
    if (url.substr(-1) === "/") {
      return url;
    }
    return `${url}/`;
  }
}

export default HttpService;
