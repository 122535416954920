import HttpService from "@/modules/core/network/http-service";

class ProducerService extends HttpService {
  constructor() {
    super("produtores");
  }

  getById(id) {
    return this.get(id);
  }
}

export default new ProducerService();
