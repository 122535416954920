import HttpService from "@/modules/core/network/http-service";

class ConvidadosService extends HttpService {
  constructor() {
    super("convidados");
  }

  getDownloadExcelFile() {
    return this.get("Exportacao/produtor/xls/", {
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
      },
      responseType: "arraybuffer"
    });
  }
}

export default new ConvidadosService();
