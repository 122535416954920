import HttpService from "@/modules/core/network/http-service";

class ProductiveUnitService extends HttpService {
  constructor() {
    super("unidades_produtivas");
  }

  setHarvestCertification(payload) {
    const { id, safra } = payload;
    return this.patch({ safra }, "alterar_safra", id);
  }

  getByIdAndHarvestId(id, harvestId) {
    const URI = `${id}/safra/${harvestId}`;
    return this.get(URI);
  }
}

export default new ProductiveUnitService();
