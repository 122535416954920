import Vue from "vue";
import Router from "vue-router";

import routerPaths from "@/routerPaths.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: routerPaths
});

export default router;
