import { ContentLayout } from "@/modules/core/views/templates";
import { AuthGuard } from "../core/helpers/AuthGuard";
import { Profile } from "./enums/profile.enum";

export default [
  {
    path: "",
    component: ContentLayout,
    children: [
      {
        name: "inviteds",
        path: "",
        meta: {
          auth: true,
          roles: [Profile.INVITED.id, Profile.STATE.id, Profile.ABRAPA.id]
        },
        component: () => import("@/modules/inviteds/views/pages/Inviteds.vue"),
        beforeEnter: AuthGuard
      },
      {
        name: "validate-token",
        path: "/validar-token/:token",
        meta: {
          auth: false
        },
        component: () => import("@/modules/inviteds/views/pages/ValidToken.vue")
      }
    ]
  }
];
