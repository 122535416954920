import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#014F80",
        secondary: "#b0bec5",
        success: "#8add46",
        accent: "#8c9eff",
        error: "#b71c1c",
        ["primary-text"]: "#0E0E0E",
        ["secondary-text"]: "#7F7F7F",
        ["status-accepted"]: "#2eb82e",
        ["status-refused"]: "#ff0000",
        ["status-send"]: "#d0d000",
        ["accept"]: "#00A854",
        ["refuse"]: "#FF3E55",
        ["gray-1"]: "#f6f6f6"
      }
    },
    options: {
      customProperties: true
    }
  }
});
