export const ProducerType = {
  PRODUCER: {
    id: "P",
    name: "Produtor"
  },
  GROUP: {
    id: "G",
    name: "Grupo de Produtores"
  }
};
