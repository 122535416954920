import { ContentLayout } from "@/modules/core/views/templates";
import { Profile } from "@/modules/inviteds/enums/profile.enum";
import { AuthGuard } from "@/modules/core/helpers/AuthGuard";

export default [
  {
    path: "/convites-uba",
    component: ContentLayout,
    children: [
      {
        name: "invite-uba",
        path: "",
        meta: {
          roles: [Profile.STATE.id, Profile.ABRAPA.id]
        },
        component: () => import("@/modules/invite-uba/views/pages/InvitesUBA"),
        beforeEnter: AuthGuard
      },
      {
        name: "new-invite-uba",
        path: "novo-convite",
        meta: {
          roles: [Profile.STATE.id, Profile.ABRAPA.id]
        },
        component: () =>
          import("@/modules/invite-uba/views/pages/NewInviteUBA"),
        beforeEnter: AuthGuard
      },
      {
        name: "answer-invite",
        path: "responder-convite/:id",
        meta: {
          roles: [Profile.INVITED.id]
        },
        beforeEnter: AuthGuard,
        component: () =>
          import("@/modules/invite-uba/views/pages/AnswerInviteUBA")
      }
    ]
  }
];
