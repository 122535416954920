import HttpService from "@/modules/core/network/http-service";

class ProducersGroupService extends HttpService {
  constructor() {
    super("grupo_produtores");
  }

  getById(id) {
    return this.get(id);
  }
}

export default new ProducersGroupService();
