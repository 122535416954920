import {
  initialState,
  inviteDetailInitialState,
  termPageInitialState
} from "./state";
import * as MutationTypes from "./mutation-types";

export const mutations = {
  [MutationTypes.RESET_INVITE_STATE](state) {
    state.data = initialState();
  },

  [MutationTypes.RESET_INVITE_DETAIL](state) {
    state.data = {
      ...state.data,
      inviteDetail: inviteDetailInitialState()
    };
  },

  [MutationTypes.SET_INVITE_DETAIL](state, inviteDetail) {
    state.data = {
      ...state.data,
      inviteDetail: {
        ...inviteDetail
      }
    };
  },

  [MutationTypes.TERM_PAGE_RESET_STEP](state) {
    state.data = {
      ...state.data,
      termPage: termPageInitialState()
    };
  },

  [MutationTypes.TERM_PAGE_SET_FIRST_STEP_LABEL](state, label) {
    state.data = {
      ...state.data,
      termPage: {
        stepper: {
          ...state.data.termPage.stepper,
          steps: [
            {
              step: 1,
              label: label
            },
            {
              step: 2,
              label: "Confirmação da(s) Adesão(ões)"
            }
          ]
        }
      }
    };
  },

  [MutationTypes.TERM_PAGE_SET_SECOND_STEP_LABEL](state, label) {
    state.data = {
      ...state.data,
      termPage: {
        stepper: {
          ...state.data.stepper,
          steps: [
            {
              step: 1,
              label: state.data.stepper.steps[0].label
            },
            {
              step: 2,
              label: label
            }
          ]
        }
      }
    };
  },

  [MutationTypes.TERM_PAGE_SET_CURRENT_STEP](state, currentStep) {
    state.data = {
      ...state.data,
      termPage: {
        ...state.data.termPage,
        stepper: {
          ...state.data.termPage.stepper,
          currentStep
        }
      }
    };
  },

  [MutationTypes.SET_INVITE_MODE](state, inviteMode) {
    state.data = {
      ...state.data,
      inviteMode
    };
  }
};
