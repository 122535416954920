export const userInitialState = () => ({
  nome_completo: null,
  email: null,
  cpf: null,
  convidado_id: null,
  perfis: [],
  profile: {
    id: null,
    description: null
  }
});

export const state = {
  user: userInitialState()
};
