import { ContentLayout } from "@/modules/core/views/templates";
import { Profile } from "@/modules/inviteds/enums/profile.enum";

export default [
  {
    path: "/solicitacao-excecao",
    component: ContentLayout,
    children: [
      {
        name: "exceptionRequest",
        path: "",
        meta: {
          title: "Solicitação de exceção",
          auth: true,
          roles: [Profile.STATE.id, Profile.ABRAPA.id]
        },
        component: () =>
          import(
            "@/modules/exception-request/views/pages/ExceptionRequests.vue"
          )
      }
    ]
  }
];
