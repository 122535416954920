import { Profile } from "../enums/profile.enum";

class UserService {
  getRoleUser(user) {
    return Object.values(Profile).find(profile =>
      user.perfis.some(perfil => perfil === profile.description.toUpperCase())
    );
  }
}

export default new UserService();
