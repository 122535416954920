import App from "./App.vue";
import store from "./store";
import router from "./router";

import Vue from "vue";
import VueTheMask from "vue-the-mask";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { vuetify, i18n } from "@/modules/core/plugins";
import { Vue as VueIntegration } from "@sentry/integrations";
import InfiniteLoading from "vue-infinite-loading";

if (
  process.env.VUE_APP_SENTRY_DSN &&
  (process.env.VUE_APP_SENTRY_DSN || "").indexOf("sentry") >= 0
) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true
      }),
      new Integrations.BrowserTracing()
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}

Vue.use(InfiniteLoading, {
  system: {
    throttleLimit: 500
  }
});

Vue.use(VueTheMask);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
