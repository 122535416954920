import HttpService from "@/modules/core/network/http-service";

class ExceptionRequestSbrhviService extends HttpService {
  constructor() {
    super("solicitacoes_excecao_sbrhvi");
  }

  cancel(id) {
    return this.delete(`${id}/cancelar`);
  }

  sendNotionReview({ id, body }) {
    return this.patch(body, `${id}/revisar_parecer`);
  }

  getProducers(params) {
    return this.get("produtores/", {
      params
    });
  }

  getProducersGroups(params) {
    return this.get("grupos/", {
      params
    });
  }

  opinion({ id, body }) {
    return this.patch(body, `${id}/parecer`);
  }
}

export default new ExceptionRequestSbrhviService();
