// TODO: Implement check function on every InviteStatus comparation
function checkEnum(value) {
  return this.id === value.toUpperCase();
}

export const InviteStatus = {
  INVITE_ACCEPTED: {
    id: "ACEITO",
    name: "Convite Aceito",
    check: checkEnum
  },
  INVITE_REFUSED: {
    id: "RECUSADO",
    name: "Convite recusado",
    check: checkEnum
  },
  INVITE_SENT: {
    id: "CONVIDADO",
    name: "Convite enviado",
    check: checkEnum
  },
  INVITE_CANCELED: {
    id: "CANCELADO",
    name: "Convite Cancelado",
    check: checkEnum
  }
};
