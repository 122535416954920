export const state = {
  isLoading: false,
  snackbarInfo: {
    snackbar: false,
    type: "",
    text: ""
  },
  sidebar: {
    isShow: true
  },
  hasLoggedUser: false,
  itemsTable: []
};
