import HttpService from "@/modules/core/network/http-service";

class UserSindaService extends HttpService {
  constructor() {
    super("responsaveis");
  }

  update(id, data) {
    this.patch(data, null, id);
  }

  getInvitedABRUBA(params) {
    return this.get("abr_uba", { params });
  }
}

export default new UserSindaService();
