import * as MutationTypes from "./mutation-types";

export const mutations = {
  [MutationTypes.SET_SYSTEMS](state, systems) {
    state.selectedSystems = [...systems];
  },

  [MutationTypes.SET_PRODUCER_GROUP](state, producerOrGroup) {
    state.newInvite.produtor = producerOrGroup;
    state.newInvite.produtor_id = producerOrGroup.id;
    state.newInvite.tipo_produtor = producerOrGroup.tipo;
    state.newInvite.abr.unidades_produtivas = [];
  },

  [MutationTypes.CLEAR_PRODUCER_GROUP](state) {
    state.newInvite.produtor = {
      nome: "",
      unidades_produtivas: []
    };
    state.newInvite.produtor_id = null;
  },

  [MutationTypes.RESET_NEW_INVITE](state) {
    state.selectedSystems = [];
    state.newInvite = {
      produtor: {
        nome: "",
        unidades_produtivas: []
      },
      produtor_id: null,
      sbrhvi: {
        convidado: {
          nome: null,
          cpf: null
        }
      },
      abr: {
        convidados: [],
        unidades_produtivas: []
      }
    };
  },

  // Productive unit
  [MutationTypes.ADD_PRODUCTIVE_UNIT](state, productiveUnit) {
    state.newInvite = {
      ...state.newInvite,
      abr: {
        ...state.newInvite.abr,
        unidades_produtivas: [
          ...state.newInvite.abr.unidades_produtivas,
          productiveUnit
        ]
      }
    };
  },
  [MutationTypes.REMOVE_PRODUCTIVE_UNIT](state, productiveUnit) {
    state.newInvite = {
      ...state.newInvite,
      abr: {
        ...state.newInvite.abr,
        unidades_produtivas: state.newInvite.abr.unidades_produtivas.filter(
          unity => unity.id !== productiveUnit.id
        )
      }
    };
  },

  [MutationTypes.SET_SBRHVI_USER](state, user) {
    state.newInvite = {
      ...state.newInvite,
      sbrhvi: {
        ...state.newInvite.sbrhvi,
        convidado: { ...user }
      }
    };
  },

  [MutationTypes.REMOVE_SBRHVI_USER](state, user) {
    state.newInvite = {
      ...state.newInvite,
      sbrhvi: {
        ...state.newInvite.sbrhvi,
        convidado: { nome: "", cpf: "" }
      }
    };
  },

  [MutationTypes.ADD_ABR_USER](state, user) {
    state.newInvite = {
      ...state.newInvite,
      abr: {
        ...state.newInvite.abr,
        convidados: [...state.newInvite.abr.convidados, { ...user }]
      }
    };
  },

  [MutationTypes.EDIT_ABR_USER](state, user) {
    state.newInvite = {
      ...state.newInvite,
      abr: {
        ...state.newInvite.abr,
        convidados: [
          ...state.newInvite.abr.convidados.filter(
            convidado => convidado.cpf !== user.cpf
          ),
          ...state.newInvite.abr.convidados
            .filter(convidado => convidado.cpf === user.cpf)
            .map(() => ({ ...user }))
        ]
      }
    };
  },

  [MutationTypes.REMOVE_ABR_USER](state, user) {
    state.newInvite = {
      ...state.newInvite,
      abr: {
        ...state.newInvite.abr,
        convidados: [
          ...state.newInvite.abr.convidados.filter(
            convidado => convidado.cpf !== user.cpf
          )
        ]
      }
    };
  }
};
