export const getSystems = state => state.selectedSystems;
export const getNewInvite = state => state.newInvite;
export const getProducerOrGroup = state => state.newInvite.produtor;
export const getSelectedProductiveUnits = state =>
  state.newInvite.abr.unidades_produtivas;
export const getProducerProductiveUnits = state =>
  state.newInvite.produtor.unidades_produtivas;
export const getSbrHviUser = state => state.newInvite.sbrhvi.convidado;
export const getAbrUsers = state => state.newInvite.abr.convidados;
export const getHasAbrUsers = state =>
  state.newInvite?.abr?.convidados?.length > 0;
export const getHasProducerOrGroupSelected = state => {
  return !!state.newInvite?.produtor?.id;
};
export const getCanInviteSbrhviResponsible = state => {
  return state.newInvite?.produtor?.habilitar_convite_sbrhvi;
};
export const getExemptSbrhvi = state => {
  return state.newInvite?.produtor?.possui_insencao_sbrhvi;
};
export const getHasSbrHviResponsible = state => {
  return state.newInvite?.produtor?.possui_responsavel_sbrhvi;
};
export const getSbrHviResponsibles = state => {
  return state.newInvite.produtor.responsaveis_sbrhvi;
};

export const getOtherResponsibles = state =>
  state.newInvite.produtor.responsaveis_sbrhvi_registrados;
