import store from "@/store";

export const AuthGuard = (to, previus, next) => {
  const { roles, auth } = to.meta;

  if (!auth) {
    return next();
  }

  const userProfile = store.getters["inviteds/getUserProfile"];

  if (!userProfile) {
    return next({ name: "redirectSSO" });
  }

  if (!roles.includes(userProfile.id)) {
    store._actions.toggleSnackbar[0]({
      text: "Você não possui permissão para acessar este recurso!",
      type: "error"
    });

    if (previus.path === "/" || previus.name === "inviteds") {
      return next({ name: "redirectSSO" });
    }

    return next({ name: "inviteds" });
  }

  return next();
};
