import HttpService from "@/modules/core/network/http-service";

class InviteUBAService extends HttpService {
  constructor() {
    super("convidados_uba");
  }

  deleteInvitedUba({ sendingId, invitedId }) {
    return this.delete(`${sendingId}/convidado/${invitedId}`);
  }

  exportCSV(params) {
    return this.get("exportar", {
      params,
      responseType: "blob",
      headers: { Accept: "text/csv" }
    });
  }

  exportExcel(params) {
    return this.get("exportar", {
      params,
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    });
  }

  getFilters() {
    return this.get("filtros");
  }
}

export default new InviteUBAService();
